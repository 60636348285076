import request from './request';

export const RTC_APP_ID = process.env.REACT_APP_TEST_APPID || 'd8lk7l4ed';

export const HOST = process.env.REACT_APP_TEST_HOST || 'https://api-demo.qnsdk.com';

export const PREFIX = '/v1/rtc';
//192.168.2.60:8586/Api/APP/CreateVideoRoomByPhone?mobilephone=13666237143

//export const kHOST = 'http://192.168.2.201:8131';
//export const kHOST = "http://123.56.183.169:9092";
// export const kHOST = "http://cr.dazhutv.com:8134";
export const kHOST = "https://glapp.dazhutv.com";
//export const kHOST = "http://cr.xishuirm.com:8131";
//export const kHOST = "http://cr.kjzx.net:8131";
// export const kHOST = "https://glapp.scqstv.com";
//export const kWebSocketHOST = "ws://cr.xishuirm.com:8132";
//export const kWebSocketHOST = "ws://cr.kjzx.net:8132";
// export const kWebSocketHOST = "wss://socket.scqstv.com";
// export const kWebSocketHOST = "ws://cr.dazhutv.com:8135";
export const kWebSocketHOST = "wss://socket.dazhutv.com";
//export const kWebSocketHOST = "ws://123.56.183.169:8137";
//export const kWebSocketHOST = "ws://192.168.2.201:8132";

export const kPREFIX = "/Api/APP/";

export const API = {
         LIST_ROOM: `/rooms/app/${RTC_APP_ID}`,
         LIST_USERS: (appid: string, roomid: string) =>
           `${HOST}${PREFIX}/users/app/${appid ||
             RTC_APP_ID}/room/${roomid}`,
         GET_APP_CONFIG: (appid?: string) =>
           `${HOST}${PREFIX}/app/${appid || RTC_APP_ID}`,
         JOIN_ROOM_TOKEN: (
           roomid: string,
           userid: string,
           appid?: string
         ) =>
           `${HOST}${PREFIX}/token/app/${appid ||
             RTC_APP_ID}/room/${roomid}/user/${userid}`,
         CREATE_ROOM_TOKEN: (
           roomid: string,
           userid: string,
           appid?: string
         ) =>
           `${HOST}${PREFIX}/token/admin/app/${appid ||
             RTC_APP_ID}/room/${roomid}/user/${userid}`,
         REQUEST_ROOM_TOKEN: (roomname: string) =>
           `${kHOST}${kPREFIX}CreateVideoRoomByPhone?mobilephone=18888888888&roomalias=${roomname}`,
         REQUEST_LINK_USER: (centeruid:string,inviteephone: string) =>
           `${kHOST}${kPREFIX}LinkUser?userid=${centeruid}&inviteephone=${inviteephone}`,
         VIDEO_INVITATIONBYPHONE: (inviteephone: string, roomname: string) =>
           `${kHOST}${kPREFIX}VideoInvitationByPhone?userphone=18888888888&inviteephone=${inviteephone}&roomname=${roomname}&type=2`,
  VIDEO_INVITATION: (centerId: string, userId: string, roomname: string) =>
    `${kHOST}${kPREFIX}VideoInvitation?id=${centerId}&userphone=${userId}&roomname=${roomname}`,
         REQUEST_USER_LIST: () => `${kHOST}${kPREFIX}GetAllUsers`,
         LEAVE_ROOM: (roomname: string, userid:string) =>
           `${kHOST}${kPREFIX}LeaveVideoRoom?userid=${userid}&roomname=${roomname}`,
         VIDEO_INVITATIONRESULT: (userId: string, inviteeid: string, roomname: string, isgaree: string, websocketId: string) =>
           `${kHOST}${kPREFIX}VideoInvitationResult?type=2&userid=${userId}&inviteeid=${inviteeid}&roomname=${roomname}&result=${isgaree}&websocketId=${websocketId}`,
       };
export const getUserList = () => {
  const api = API.REQUEST_USER_LIST;

  const requestURL = `${api()}`;
  return request(requestURL, 'GET');
}

export const getToken = (appid: string, roomid: string, userid: string) => {
  const api = userid === 'admin' ? API.CREATE_ROOM_TOKEN : API.JOIN_ROOM_TOKEN;
  // 此处服务器 URL 仅用于 Demo 测试！随时可能 修改/失效，请勿用于 App 线上环境！
  // 此处服务器 URL 仅用于 Demo 测试！随时可能 修改/失效，请勿用于 App 线上环境！
  // 此处服务器 URL 仅用于 Demo 测试！随时可能 修改/失效，请勿用于 App 线上环境！
  const requestURL = `${api(roomid, userid, appid)}?bundleId=demo-rtc.qnsdk.com`;
  return request(requestURL, 'GET');
}

export const createVideoRoom = (roomname: string) => {
  const api = API.REQUEST_ROOM_TOKEN;

  const requestURL = `${api(roomname)}`;
  return request(requestURL, 'GET');
}

export const requestLinkUser = (centeruid: string,inviteephone: string) => {
         const api = API.REQUEST_LINK_USER;

         const requestURL = `${api(centeruid,inviteephone)}`;
         return request(requestURL, "GET");       
};

export const reportLeaveRoom = (roomname: string, userid: string) => {
  const api = API.LEAVE_ROOM;

  const requestURL = `${api(roomname, userid)}`;
  return request(requestURL, "POST");
};

export const videoInvitationBYPHONE = (inviteeId: string, roomname: string) => {

  const api = API.VIDEO_INVITATIONBYPHONE;
  const requestURL = `${api(inviteeId,roomname)}`;

  return request(requestURL,'POST');
}

export const videoInvitation = (centerId: string, userId: string, roomname: string) => {

  const api = API.VIDEO_INVITATION;
  const requestURL = `${api(centerId, userId, roomname)}`;

  return request(requestURL, 'POST');
}

export const videoInvitationResult = (userId: string, inviteeid: string, roomname: string, isgaree: string, websocketId: string) => {

  const api = API.VIDEO_INVITATIONRESULT;
  const requestURL = `${api(userId, inviteeid, roomname, isgaree, websocketId)}`;

  return request(requestURL, 'POST');
}

export const FLV_PATH = (roomName: string) => `https://pili-hdl.qnsdk.com/sdk-live/${roomName}.flv`;

export const HLS_PATH = (roomName: string) => `https://pili-hls.qnsdk.com/sdk-live/${roomName}.m3u8`;
