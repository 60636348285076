export default class WSUser {
    
    public id: string = "";
    
    // phone+id
    public userid: string = "";

    public name: string="";
    
    public alias: string="";
    
    public phone: string="";

    public headicon: string = "";

    public roleid: string = "";

    public rolename: string = "";

    public organizid: string = "";

    public organizename: string = "";

}