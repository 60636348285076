import { observable, action, runInAction, computed } from "mobx";

import { kWebSocketHOST } from "../common/api";
import SocketMsg from "../models/SocketMsg";

export class SocketStore {
         @observable
         public socketServer = "";

         @observable
         public socketID = "";
         //  @observable
         //  public onInviteMessageReturn!: ((this: WebSocket, isAgeree:string) => any) | null;

         @observable
         public socketClient = new WebSocket(
           kWebSocketHOST + "/msgsocket?linktype=1&id=tempid"
         );

         @observable
         public currentSocketMsg = new SocketMsg("", "","","","");
  
         //请求加入房间队列
         @observable.deep
         public requestJoinRoomQueue : Map<string, SocketMsg> = new Map();

         public initSocketStore(uid: string) {
           //this.socketClient.close();
           this.socketServer =
           kWebSocketHOST + "/msgsocket?linktype=1&id="+uid;
           this.socketClient = new WebSocket(this.socketServer);

           let container = this;
           this.socketClient.onopen = function() {
             //this.send("shake hands");
             console.log("视频连线Socket连接成功");
           };

           //this.socketClient.send("test");

           this.socketClient.onclose = function(evt) {
             console.log("WebSocketClosed!");
             //container.initSocketStore(uid);
             this.close();
           };

           this.socketClient.onerror = function(evt) {
             console.log("WebSocketError!" + evt);
             container.initSocketStore(uid);
             container.reconnect();
           };
         }

         public reconnect() {
           if (this.socketClient.readyState === -1) {
             this.socketClient = new WebSocket(this.socketServer);
           }
         }

         public onMsg() {

          let parent = this;

           this.socketClient.onmessage = function(evt) {
             console.log(evt.data);
             let reviver = JSON.parse(evt.data);
             switch (reviver.datatype) {
               case 51:
                //  if (reviver.datacontent.isagree === "true") {
                //    "对方已接受邀请，等待对方加入房间";
                //  } else {
                //    "对方当前不方便通话，已拒绝邀请";
                //  }
                 break;
               default:
                 break;
             }
           };
         }

         public invite(user: string) {
           let container = this;

           let data = {};
           var jsonstr =
             "[{'name':'a','value':1},{'name':'b','value':2}]";
           jsonstr =
             "{'datacontent': {'userphone':'" +
             user +
             "'},'datatype': '5'}";
           var jsondata = eval("(" + jsonstr + ")");

           this.socketClient.onopen = function() {
             //JSON.stringify
             this.send(jsondata);
           };
         }

         public forbiddenUser(userphone: string) {
           var jsonstr =
             "[{'name':'a','value':1},{'name':'b','value':2}]";
           jsonstr =
             "{'datacontent': {'userphone':'" +
             userphone +
             "'},'datatype': '52'}";
           var jsondata = eval("(" + jsonstr + ")");

           if (this.socketClient.readyState === 1) {
             this.socketClient.send(jsonstr);
           }

           //    this.socketClient.onopen = function() {
           //      this.send(jsondata);
           //    };
         }

         public forbiddenAllUser(roomname: string,uid:string) {
           var jsonstr =
             "[{'name':'a','value':1},{'name':'b','value':2}]";
           jsonstr =
             "{'datacontent': {'roomname':'" +
             roomname +"', 'uid': '"+uid+
             "'},'datatype': '53'}";

           if (this.socketClient.readyState === 1) {
             this.socketClient.send(jsonstr);
           }

           //    this.socketClient.onopen = function() {
           //        this.send(jsondata);
           //    };
         }

         public cancleForbiddenUser(userphone: string) {
           var jsonstr =
             "[{'name':'a','value':1},{'name':'b','value':2}]";
           jsonstr =
             "{'datacontent': {'userphone':'" +
             userphone + 
             "'},'datatype': '54'}";
           var jsondata = eval("(" + jsonstr + ")");

           if (this.socketClient.readyState === 1) {
             this.socketClient.send(jsonstr);
           }

           //    this.socketClient.onopen = function() {
           //      this.send(jsondata);
           //    };
         }

         public cancleForbiddenAllUser(roomname: string, uid: string) {
           var jsonstr =
             "[{'name':'a','value':1},{'name':'b','value':2}]";
           jsonstr =
             "{'datacontent': {'roomname':'" +
           roomname + "', 'uid': '" + uid +
             "'},'datatype': '55'}";

           if (this.socketClient.readyState === 1) {
             this.socketClient.send(jsonstr);
           }

           //    this.socketClient.onopen = function() {
           //      this.send(jsondata);
           //    };
         }

         public TakePeopleOut(userphone: string) {
           var jsonstr =
             "[{'name':'a','value':1},{'name':'b','value':2}]";
           jsonstr =
             "{'datacontent': {'userphone':'" +
             userphone +
             "'},'datatype': '56'}";
           var jsondata = eval("(" + jsonstr + ")");

           if (this.socketClient.readyState === 1) {
             this.socketClient.send(jsonstr);
           }
         }

         public disposeInvateMsg()
         {
           this.currentSocketMsg = new SocketMsg("", "", "", "", "");
         }

         public hasInvateMsg()
         {
          if(this.currentSocketMsg.OriginatorId !== ""){
            return true;
          }else{
            return false;
          }
         }

  //申请人ID applyid;
  public responseJoinRoom(usernick: string, applyid: string, result: string) {

    var jsonstr = "";
    jsonstr =
      "{'datacontent': {'usernick':'" + usernick + "','applyid':'" + applyid + "', 'result': '" + result + "'},'datatype': '59'}";
    var jsondata = eval("(" + jsonstr + ")");

    if (this.socketClient.readyState === 1) {
      this.socketClient.send(jsonstr);
    }
  }

  
  @action
  public setJoinRoomQueue(userid: string, msg: SocketMsg) {
    this.requestJoinRoomQueue.set(userid, msg);

  }
       }

export default new SocketStore();
