import {
  Avatar,
  Fab,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Button
} from "@material-ui/core";

import { MenuItemProps } from "@material-ui/core/MenuItem";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import {
  FileCopy as ContentCopyIcon,
  Phone as PhoneIcon,
  MicNone,
  MicOff,
  Videocam as VideocamIcon,
  VideocamOff as VideocamOffIcon,
  Backspace,
  BackspaceRounded,
  SupervisorAccountTwoTone,
  AccountCircleOutlined,
  MicOffTwoTone,
  MicNoneTwoTone,
  MusicOffTwoTone,
  AddAPhoto,
  Call
} from "@material-ui/icons";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Clipboard from "clipboard";
import { inject, observer } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import { RoomState, Track as RTCTrack, Track } from "pili-rtc-web";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { verifyRoomId, getTrackNmae, randomStringGen } from "../common/utils";
import { RoomStore } from "../stores/roomStore";
import { UserStore } from "../stores/userStore";
import UserPlayer from "../components/UserPlayer";
import { kWebSocketHOST } from "../common/api";
import socketStore, { SocketStore } from "../stores/socketStore";

import qs from "qs";
import { MessageStore } from "../stores/messageStore";
import { MenuStore } from "../stores/menuStore";
import InfoPanel from "../components/InfoPanel";
import { string } from "prop-types";
import ReactDOM from "react-dom";
import { truncate } from "fs";
import User from "../models/User";
import SocketMsg from "../models/SocketMsg";
import { action } from "mobx";
import userHeaderIcon from "../assets/usersend.png";

const drawerWidth = 240;
const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden",
      padding: 0,
      height: "100vh",
      width: "100vw"
    },
    progress: {
      margin: theme.spacing.unit * 2
    },
    dialogContentText: {
      margin: theme.spacing.unit * 2,
      lineHeight: "40px"
    },
    header: {
      padding: `${theme.spacing.unit * 3}px`,
      height: "80px",
      overflow: "visible",
      background: "#1100B1"
    },
    headerContent: {
      height: `${80 - theme.spacing.unit * 3}px`,
      lineHeight: `${80 - theme.spacing.unit * 3}px`,
      overflow: "visible"
    },
    zoom: {
      width: "100%",
      height: "91vh",
      flexGrow: 1,
      // background: "#7b7a7a",
    },
    footer: {
      padding: `${theme.spacing.unit * 3}px`,
      height: "10vh",
      background: "#1175B1",
      marginBottom: "0px",
    },
    leftScreenView: {
      flexGrow: 1,
      width: "50vw",
      height: "95vh"
    },
    selfVideoView: {
      padding: `0px`,
      overflowX: "hidden",
      overflowY: "hidden",
      // marginRight: "16px",
      width: "100%",
      height: "51vh",
      background: "#1175B1"
    },
    userListContent: {
      padding: `8px`,
      overflowX: "hidden",
      overflowY: "hidden",
      marginTop: "0px",
      marginRight: "16px",
      width: "100%",
      height: "50vh"
    },
    userWindows: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: "30%",
      height: "30%",
      //zIndex: -1,
      //marginRight: "10px",
      //background: "#1100B1",
      paddingTop: "8px",
      paddingRight: "2%",
    },
    bigScreenView: {
      padding: `0px`,
      overflowX: "hidden",
      overflowY: "hidden",
      width: "50vw",
      height: "100%",
      top: 0
    },
    UserPlayer: {
      zIndex: 2
    },
    selfScreens: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      marginTop: "-10px"
    },
    screen: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: "100%",
      height: "100%",
      zIndex: -1,
      paddingLeft: "600px",
      paddingRight: "0px",
      paddingBottom: "60px",
      textAlign: "center",
      boxAlign: "center"
    },
    chip: {
      position: "relative",
      zIndex: 1,
      marginLeft: "1px",
      maxHeight: "50px",
      background: "#1175B1",
      textAlign: "center",
      marginBottom: "-35px",
      alignContent: "start"
    },
    programmechip: {
      position: "relative",
      zIndex: 3,
      maxHeight: "50px",
      background: "#1175B1",
      marginTop: "-55px",
      alignContent: "start"
    },
    userlistchip: {
      position: "relative",
      zIndex: 1,
      maxHeight: "23px",
      width: "100%",
      background: "#1175B1",
      marginTop: "-20px",
      alignContent: "center",
      textAlign: "center"
    },
    gridList: {
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)"
    },
    infoPanel: {
      background: "rgba(28,28,28,0.8)",
      borderRadius: "4px",
      color: "#fff",
      left: "10px",
      position: "absolute",
      top: "10px",
      zIndex: 64,
      minWidth: "0em",
      width: "0px",
      height: "0px"
    },
    activeFab: {
      background: "#ef5350 !important"
    },
    holder: {
      width: "56px"
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end"
    },
    drawerContent: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    drawerContentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    }
  });

interface RoomRouterProps {
  roomid: string | undefined;
}

interface Props extends WithStyles<typeof styles> {
  routerStore: RouterStore;
  userStore: UserStore;
  roomStore: RoomStore;
  messageStore: MessageStore;
  menuStore: MenuStore;
}

@inject("routerStore", "userStore", "roomStore", "messageStore", "menuStore")
@observer
class Room extends Component<Props & RouteComponentProps<RoomRouterProps>, {}> {

  state = {
    isCustomizeControlsOfPerson: false,
    isNeedRefreshUserList: true,
    currentMainCallByUserID: "",
    open: false,
    checked: [0],
    openInviteMsg: false,
    invateUserName: "",
  };

  private socketStore = new SocketStore();
  public currentMainCallByUserPhone: string = "";
  public invateRoomToken = "";

  async componentDidMount() {
    console.log(this.props.location.search);

    const query_params = new URLSearchParams(this.props.location.search);
    let guest_user = query_params.get("invitee");
    let uid = query_params.get("uid");
    let roomToken = query_params.get("token");
    let roomname = query_params.get("roomname");

    if (uid) {
      this.props.roomStore.centerUserId = uid;
    }
    if (guest_user) {
      this.currentMainCallByUserPhone = guest_user;
    }
    if (roomname) {
      this.props.roomStore.roomname = roomname;
    }

    const roomid = this.props.match.params.roomid || "Centers";
    const qsobj = qs.parse(this.props.routerStore.location.search.substr(1));
    // 如果加入房间的时候没有用户名，随机分配一个
    if (!this.props.userStore.id) {
      this.props.userStore.setIdNoStore(`guest${randomStringGen(3)}`);
    }
    if (!qsobj.roomToken && !verifyRoomId(roomid))
      return this.props.routerStore.push("/");
    const { roomStore, messageStore } = this.props;
    if (roomStore.state !== RoomState.Idle) {
      messageStore.hideLoading();
      return;
    }

    if (qsobj.appId) {
      roomStore.setAppId(qsobj.appId);
    }

    let token: string | undefined = qsobj.roomToken;
    if (roomToken !== null && roomToken !== undefined) token = roomToken;

    let parent = this;

    let tracks: RTCTrack[] | undefined;
    messageStore.setLoadingText("加入房间中");

    try {
      if (!token) {
        //if (!guest_user) guest_user = "138888888888";
        if (guest_user === null || guest_user === undefined) {
          console.log("guest_user 当前为：" + guest_user);
          guest_user = "136888888888";
        }

        roomStore.setId(roomid);
        const location = this.props.routerStore.location.pathname;
        [tracks] = await Promise.all([
          this.selectTracks().then((tracks: any) => {
            //console.log(this.props.routerStore.location.pathname);
            if (location !== this.props.routerStore.location.pathname) {
              roomStore.releaseLocalTracks();
              return Promise.reject();
            }
            return tracks;
          }),

          roomStore.requestLinkUserRoom(guest_user).then(roomdata => {
            //console.log(roomdata);
            token = roomdata.data.roomToken;

            if (uid === null || uid === undefined) uid = "18888888888";

            this.initSocket(uid);
            //this.scoketRequestCall(this.props.roomStore.centerId);

            if (location !== this.props.routerStore.location.pathname)
              if (location !== this.props.routerStore.location.pathname)
                return Promise.reject();
            return roomStore.joinRoom(token);
          })
        ]).finally(() => {
          if (roomStore.state === RoomState.Idle) {
            roomStore.releaseLocalTracks();
          }
        });
      } else {
        [tracks] = await Promise.all([
          this.selectTracks(),
          this.props.roomStore.joinInvateRoom().then(roomdata => {
          
            if (uid === null || uid === undefined) uid = "138888888888";
            this.props.roomStore.centerUserId = uid;
            this.initSocket(uid)
          }),
          roomStore.joinRoom(token)
        ]).finally(() => {
          if (roomStore.state === RoomState.Idle) {
            roomStore.releaseLocalTracks();
          }
        });
      }
    } catch (error) {
      console.log(error);
      messageStore.hideLoading();
      this.props.routerStore.push("/");
      messageStore.show("加入房间失败");
      return;
    }
    console.log("token: " + token);
    if (tracks) {
      this.publish(tracks);
    }
    if (!roomid) {
      this.props.routerStore.replace(`/room/${this.props.roomStore.id}`);
    }
    this.props.roomStore.subscribeAll().catch(e => {
      console.log(e);
      messageStore.showAlert({
        show: true,
        title: "订阅失败",
        content: "自动订阅失败，请手动订阅"
      });
    });

    this.loadUserList();
  }

  componentWillUnmount() {
    this.props.roomStore.requestVideoInvitationResult(this.socketStore.currentSocketMsg.OriginatorId, this.socketStore.currentSocketMsg.inviteeid, this.socketStore.currentSocketMsg.RoomName, "1", this.socketStore.socketID);
    this.socketStore.disposeInvateMsg();

    this.props.roomStore.leaveRoom();
    this.props.roomStore.requestLeaveRoom(this.props.userStore.id);
    this.props.messageStore.hideLoading();
  }

  publish = (tracks: RTCTrack[]) => {
    const { messageStore } = this.props;
    messageStore.setLoadingText("发布中");
    this.props.roomStore
      .publish(tracks)
      .then(() => {
        messageStore.hideLoading();
      })
      .catch(e => {
        console.log(e);
        messageStore.hideLoading();
        messageStore.showAlert({
          show: true,
          title: "发布失败",
          content: "请手动重新发布"
        });
      });
  };

  selectTracks = (): Promise<RTCTrack[] | undefined> => {
    const { roomStore, messageStore } = this.props;
    return roomStore.getSelectTracks().catch(e => {
      console.log(e);
      messageStore.hideLoading();
      switch (e.code) {
        case 11010:
          messageStore.showAlert({
            show: true,
            title: "没有权限",
            content:
              "获取摄像头/麦克风权限被拒绝，请手动打开摄像头/麦克风权限后重新进入房间"
          });
          break;
        case 11009:
          messageStore.showAlert({
            show: true,
            title: "Chrome 插件异常",
            content:
              "您可能没有安装录屏插件或者录屏插件没有升级，请到这里安装最新的录屏插件 https://doc.qnsdk.com/rtn/web/docs/screen_share#1"
          });
          break;
        case 11008:
          messageStore.showAlert({
            show: true,
            title: "浏览器不支持",
            content:
              "抱歉，您的浏览器不支持屏幕共享，请使用 Chrome 或者 Firefox"
          });
          break;
        case 11013:
          messageStore.showAlert({
            show: true,
            title: "获取录屏权限被拒绝",
            content: "请刷新页面手动重新发布"
          });
          break;
        default:
          messageStore.showAlert({
            show: true,
            title: "没有数据",
            content: `无法获取摄像头/麦克风数据，错误代码: ${e.name}`
          });
      }
      return undefined;
    });
  };

  handleMenuClose = this.props.menuStore.close;

  handlePublish = async () => {
    if (this.props.roomStore.publishedTracks.size === 0) {
      this.publish(await this.props.roomStore.getSelectTracks());
    } else {
      this.props.roomStore.unpublish();
    }
  };

  handleSubscribe = (trackId: string) => async () => {
    this.handleMenuClose();
    try {
      await this.props.roomStore.subscribe([trackId]);
    } catch (error) {
      this.props.messageStore.showAlert({
        show: true,
        title: "订阅失败",
        content: error.toString()
      });
    }
  };

  handleUnsubscribe = (trackId: string) => async () => {
    await this.props.roomStore.unsubscribe([trackId]);
    this.handleMenuClose();
  };

  handleUserClick(userid: string, e: React.MouseEvent<HTMLElement>): void {
    e.preventDefault();
    const user = this.props.roomStore.users.get(userid);
    if (!user || user.id === this.props.userStore.id) {
      return;
    }
    const menulist = [] as MenuItemProps[];

    let userCurrentMuteAllAudioState = user.currentMuteAllAudioState;

    if(this.props.roomStore.isadmin){
      if (userCurrentMuteAllAudioState) {
        menulist.push({
          children: "取消禁言",
          onClick: this.handleCancleForbiddenUser(user)
        });
      } else {
        menulist.push({
          children: "禁言",
          onClick: this.handleForbiddenUser(user)
        });
      }

      menulist.push({
        children: "踢出房间",
        onClick: this.TakePeopleOut(user)
      });
    }

    if (userid !== this.state.currentMainCallByUserID) {
      //切换主视频画面
      menulist.push({
        children: "切换到大屏",
        onClick: this.handleSwitchMainScreen(userid)
      });
    }

    if (menulist.length === 0) {
      // menulist.push({
      //   children: "未发布",
      //   disabled: true
      // });
    }
    this.props.menuStore.open(e.currentTarget, menulist);
  }

  loadUserList = () => async () => {
    try {
      this.props.roomStore.requestUserList().then(roomdata => {
        console.log(roomdata);
        //token = roomdata.data;
        //this.initWebsocket();
      });
    } catch (error) {
      console.log(error);
      this.props.messageStore.hideLoading();
      //this.props.routerStore.push("/");
      this.props.messageStore.show("获取用户列表失败");
      return;
    }
  };

  handleSwitchMainScreen = (userid: string) => async () => {
    this.handleMenuClose();

    //this.props.roomStore.removeVideoElment(userid);
    this.state.currentMainCallByUserID = userid;
    //右侧大屏
    this.setState({ currentMainCallByUserID: userid });

    this.props.roomStore.setBigScreenUser(userid);
    //this.state.isCustomizeControlsOfPerson = true;
    this.setState({ isCustomizeControlsOfPerson: true });

    // const localVideo = document.getElementsByClassName("bigScreenView");
    //const selfVideo = document.getElementsByClassName("selfScreens");

    //document.removeChild();

    this.setState({ isNeedRefreshUserList: false });
    this.setState({ isNeedRefreshUserList: true });

    // let userss: Map < string, string > = new Map();
    // Array.from(this.props.roomStore.users.values())
    //   .filter(
    //     v =>
    //       v.tracks.size !== 0 &&
    //       v.id !== this.props.userStore.id &&
    //       v.phone === this.currentMainCallByUserPhone
    //   )
    //   .map(v => {
    //     this.chair = v;
    //   });
  };

  @action
  initSocket(uid:string):void{

    this.socketStore.initSocketStore(uid);

    let parent = this;

    this.socketStore.socketClient.onmessage = function (evt) {
      console.log(evt.data);
      let reviver = JSON.parse(evt.data);
      parent.props.messageStore.hideLoading();

      switch (reviver.datatype) {
        case "0":
          parent.socketStore.socketID = reviver.datacontent.socketid;
          break;
        case "5":
          if(parent.socketStore.hasInvateMsg()===false) {
            parent.showInviteMsg(reviver.datacontent.OriginatorNick);
            parent.invateRoomToken = reviver.datacontent.RoomToken;
            parent.socketStore.currentSocketMsg = new SocketMsg(reviver.datacontent.OriginatorId, reviver.datacontent.OriginatorNick, parent.props.roomStore.centerUserId, reviver.datacontent.RoomName, reviver.datacontent.RoomToken);
          }else{
            parent.props.roomStore.requestVideoInvitationResult(reviver.datacontent.OriginatorId, parent.props.roomStore.centerUserId, reviver.datacontent.RoomName, "0", parent.socketStore.socketID);
          }
          break;
        case "51":
          if (reviver.datacontent.isagree === true) {
            parent.props.messageStore.show("对方已接受邀请，等待对方加入房间");
          } else {
            parent.props.messageStore.show("对方当前不方便通话，已拒绝邀请");
          }
          break;
        case "57":
          //成为房主
          parent.props.messageStore.show("您已成为新的管理员");
          break;
        case "58":
          //其他用户申请加入此房间
          let userid = reviver.datacontent.userid;
          let usernick = reviver.datacontent.usernick;
          let roomname = reviver.datacontent.roomname;

          let request = new SocketMsg(userid, usernick, "", roomname, "");
          parent.socketStore.setJoinRoomQueue(request.OriginatorId, request);
          break;
        default:
          break;
      };
    };
  }
  scoketRequestCall(uid: string): void {
    var ws = new WebSocket(kWebSocketHOST + "/msgsocket?id=" + uid);
    //parent.props.roomStore.
    ws.onopen = function() {
      ws.send("Accept!");
    };

    let parent = this;

    ws.onmessage = function(evt) {
      console.log(evt.data);
      let reviver = JSON.parse(evt.data);
      if (reviver.result && reviver.result === 1) {
        parent.props.messageStore.show("对方已接受邀请，等待对方加入房间");
      } else if (reviver.result && reviver.result !== 1) {
        //关闭房间
        parent.props.messageStore.showAlert({
          show: true,
          title: "已拒绝",
          content: "对方当前不方便通话，已拒绝邀请"
        });
        //window.location.href = "about.blank";
        //window.close();

        //window.history.go(-1);
        // window.opener = null;
        // window.open('', '_self','');
      }
    };

    ws.onclose = function(evt) {
      console.log("WebSocketClosed!");
      ws.close();
    };

    ws.onerror = function(evt) {
      console.log("WebSocketError!" + evt);
    };
  }

  handleDrawerOpen = () => {
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleInvitation = (uid: string) => () => {
    //this.props.messageStore.showLoading("正在邀请中");
    console.log(this.props.roomStore.centerUserId + " and  " + this.props.roomStore.roomId);
    this.props.roomStore.requestVideoInvitation(uid);
    //this.props.roomStore.centerUserId;
    //this.props.roomStore.roomId;
  };

  handleForbiddenUser = (user: User) => async () => {
    this.handleMenuClose();
    let userphone = user.phone;
    //调用禁音接口
    this.socketStore.forbiddenUser(userphone);

    user.currentMuteAllAudioState = !user.currentMuteAllAudioState;
  };

  handleCancleForbiddenUser = (user: User) => async () => {
    this.handleMenuClose();
    let userphone = user.phone;
    //调用取消禁音接口
    this.socketStore.cancleForbiddenUser(userphone);
    user.currentMuteAllAudioState = !user.currentMuteAllAudioState;
  };

  handleForbiddenAllUser = () => {
    //调用禁音接口
    if (this.props.roomStore.currentMuteAllAudioState === false) {
      this.socketStore.forbiddenAllUser(this.props.roomStore.roomname,this.props.roomStore.centerUserId);
    } else {
      this.socketStore.cancleForbiddenAllUser(this.props.roomStore.roomname, this.props.roomStore.centerUserId);
    }

    this.props.roomStore.toggleMuteAllAudio();
    //this.props.roomStore.currentMuteAllAudioState = !this.props.roomStore.currentMuteAllAudioState;
  };

  TakePeopleOut= (user: User) => async () => {
    this.handleMenuClose();
    let userphone = user.phone;
    //调用禁音接口
    this.socketStore.TakePeopleOut(userphone);

    if(this.state.currentMainCallByUserID == user.id) {
      this.setState({ currentMainCallByUserID:""});
    }
  };

  showInviteMsg(username: string)
  {
    this.setState({ invateUserName: username});
    this.setState({ openInviteMsg: true});
  }

  acceptInvite = () => 
  {

    this.setState({ openInviteMsg: true });
    this.setState({ openInviteMsg: false });

    if (this.invateRoomToken && this.socketStore.hasInvateMsg){
      let parent = this;
      this.props.roomStore.roomname = parent.socketStore.currentSocketMsg.RoomName;

      Promise.all([
        this.props.roomStore.requestVideoInvitationResult(this.socketStore.currentSocketMsg.OriginatorId, this.socketStore.currentSocketMsg.inviteeid, this.socketStore.currentSocketMsg.RoomName, "1", this.socketStore.socketID),
        this.props.roomStore.leaveRoom(),
        this.props.roomStore.requestLeaveRoom(this.props.userStore.id),
        this.socketStore.disposeInvateMsg(),
      ]).finally(() => {
        //const location = this.props.routerStore.location.pathname;
        this.props.routerStore.push(`/?uid=${this.props.roomStore.centerUserId}&token=${this.invateRoomToken}&roomname=${this.props.roomStore.roomname}`);
        //this.forceUpdate();
        window.location.reload();
      });
      
      return;
      
      let tracks: RTCTrack[] | undefined;
      Promise.all([
        this.selectTracks(),
        this.props.roomStore.joinRoom(this.invateRoomToken),
        this.handlePublish(),
        this.props.roomStore.subscribeAll().catch(e => {
          console.log(e);
          parent.props.messageStore.showAlert({
            show: true,
            title: "订阅失败",
            content: "自动订阅失败，请手动订阅"
          });
        }),
      ]).finally(() => {
        if (this.props.roomStore.state === RoomState.Idle) {
          this.props.roomStore.releaseLocalTracks();
        }
      });

    }else{
      this.props.messageStore.show("获取邀请人的房间数据错误，请稍后再试");
    }
  }

  unacceptInvite = () =>  
  {
    this.state.openInviteMsg = false;
    this.setState({ openInviteMsg: false });
    this.props.roomStore.requestVideoInvitationResult(this.socketStore.currentSocketMsg.OriginatorId, this.props.userStore.id, this.socketStore.currentSocketMsg.RoomName, "0", this.socketStore.socketID);
    this.socketStore.disposeInvateMsg();
  }

  acceptJoinRoom = (userid: string, e: React.MouseEvent<HTMLElement>): void => {

    // this.setState({ openInviteMsg: true });
    // this.setState({ openInviteMsg: false });
 
    if (this.socketStore.requestJoinRoomQueue.has(userid)) {
      const socketMsg = this.socketStore.requestJoinRoomQueue.get(userid) as SocketMsg;
      this.socketStore.responseJoinRoom(socketMsg.OriginatorNick, userid, "true");
      this.socketStore.requestJoinRoomQueue.delete(userid);
    }

  }

  unAcceptJoinRoom = (userid: string, e: React.MouseEvent<HTMLElement>): void => {

    // this.setState({ openInviteMsg: false });
    // this.setState({ openInviteMsg: true });

    if (this.socketStore.requestJoinRoomQueue.has(userid)) {
      const socketMsg = this.socketStore.requestJoinRoomQueue.get(userid) as SocketMsg;
      this.socketStore.responseJoinRoom(socketMsg.OriginatorNick, userid, "false");
      this.socketStore.requestJoinRoomQueue.delete(userid);
    }
  }

  render() {
    const { classes, roomStore, menuStore } = this.props;
    const publishedAudioTracks = roomStore.publishedAudioTracks;
    const publishedVideoTracks = roomStore.publishedVideoTracks;
    const { isCustomizeControlsOfPerson } = this.state;
    const { open } = this.state;
    const { isNeedRefreshUserList } = this.state;
    const { currentMainCallByUserID } = this.state;
    const { openInviteMsg } = this.state;
    const { invateUserName } = this.state;

    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {Array.from(this.props.roomStore.allusersinfo.values())
              .filter(
                v =>
                  v.id !== this.props.userStore.id &&
                  !this.props.roomStore.users.has(v.id)
                  // v.phone !== this.currentMainCallByUserPhone
              )
              .map(v => (
                <ListItem key={v.id} role={undefined} dense button>
                  <ListItemAvatar>
                    <Avatar
                      alt="Remy Sharp"
                      src={userHeaderIcon}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={`${v.name}`} />
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="邀请"
                      onClick={this.handleInvitation(v.phone)}
                    >
                      <AddCircleOutline />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          <Divider />
          <CssBaseline />
        </Drawer>
        {/** 正文 */}
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="stretch"
          className={classes.zoom}
          onClick={this.handleDrawerClose}
        >
          {/** 屏幕左侧 视频窗口 + 用户视频列表*/}
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            wrap="nowrap"
            className={classes.leftScreenView}
          >
            {/* 指挥中心画面 */}
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              className={classes.selfVideoView}
            >
              <Chip
                avatar={<Avatar>{"C"}</Avatar>}
                label={roomStore.isadmin ? "指挥中心(管理员)":"指挥中心"}
                className={classes.chip}
                onClick={this.handleUserClick.bind(
                  this,
                  this.props.userStore.id
                )}
              />
              <UserPlayer
                local
                tracks={Array.from(roomStore.publishedTracks.values())}
                menuStore={menuStore}
                bigscreen={false}
              />
            </Grid>
            {/* 用户画面列表 */}
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignContent="flex-start"
              alignItems="flex-start"
              wrap="wrap"
              className={classes.userListContent}
            >
              {Array.from(this.props.roomStore.users.values())
                .filter(
                  v =>
                    v.tracks.size !== 0 &&
                    v.id !== this.props.userStore.id &&
                    isNeedRefreshUserList === true 
                    // && (isCustomizeControlsOfPerson
                    //   ? v.id !== currentMainCallByUserID
                    //   : v.phone !== this.currentMainCallByUserPhone)
                )
                .map(v => (
                  <Grid item className={classes.userWindows} key={v.id}>
                    <UserPlayer bigscreen={false} isMainCall={!v.onTheBigScreen} currentCallUserID={currentMainCallByUserID} userID={v.id} menuStore={menuStore} user={v} />
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.userlistchip}
                      onClick={this.handleUserClick.bind(this, v.id)}
                    >
                      {/* {v.onTheBigScreen === true ? <Call /> : <></>} */}
                      {v.onTheBigScreen===true?"(大屏)"+v.name:v.name}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          {/** 屏幕右侧 */}
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            wrap="nowrap"
            className={classes.bigScreenView}
          >
            {Array.from(this.props.roomStore.chairusers.values())
              .filter(v =>
                isCustomizeControlsOfPerson
                  ? v.tracks.size !== 0 &&
                  v.id !== this.props.userStore.id &&
                  v.id === currentMainCallByUserID
                  : v.tracks.size !== 0 &&
                  v.id !== this.props.userStore.id &&
                  v.phone === this.currentMainCallByUserPhone
              )
              .map(v => (
                <Grid
                  item
                  direction="column"
                  justify="center"
                  alignItems="stretch"
                  className={classes.selfScreens}
                >
                  <UserPlayer bigscreen={true} screen user={v} menuStore={menuStore} />
                  <Chip
                    avatar={<Avatar>{"记"}</Avatar>}
                    label={v.name}
                    className={classes.programmechip}
                    onClick={this.handleUserClick.bind(this, v.id)}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        {/** 底部工具栏 */}
        <Grid
          container
          className={classes.footer}
          direction="row"
          justify="center"
          wrap="nowrap"
          alignItems="center"
          spacing={8}
        >
          <Grid item>
            <Tooltip title="返回" placement="top-end">
              <IconButton onClick={roomStore.toggleBack}>
                <BackspaceRounded />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            {publishedAudioTracks.length !== 0 ? (
              <Tooltip title="停止音频" placement="top-end">
                <IconButton onClick={roomStore.toggleMutePublishedAudio}>
                  {!publishedAudioTracks.some(v => !v.muted) ? (
                    <MicOff />
                  ) : (
                    <MicNone />
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            {publishedVideoTracks.length !== 0 ? (
              <Tooltip title="停止视频" placement="top-end">
                <IconButton onClick={roomStore.toggleMutePublishedVideo}>
                  {!publishedVideoTracks.some(v => !v.muted) ? (
                    <VideocamOffIcon />
                  ) : (
                    <VideocamIcon />
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <Fab
              onClick={this.handlePublish}
              color={
                this.props.roomStore.publishedTracks.size > 0
                  ? "primary"
                  : "default"
              }
              classes={{
                primary: this.props.classes.activeFab
              }}
            >
              <PhoneIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Tooltip title="邀请" placement="top-end">
              <IconButton onClick={this.handleDrawerOpen}>
                <SupervisorAccountTwoTone />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            {publishedAudioTracks.length !== 0 ? (
              <Tooltip title="全体禁言" placement="top-end">
                <IconButton onClick={this.handleForbiddenAllUser}>
                  <MusicOffTwoTone />
                  {!roomStore.currentMuteAllAudioState ? (
                    <MicNoneTwoTone />
                  ) : (
                    <MicOffTwoTone />
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item className={classes.holder} />
        </Grid>
        <Dialog
          open={openInviteMsg}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"连线请求"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              用户:{invateUserName} 申请与您通话，接受将退出当前房间，加入对方房间，等待确认
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.acceptInvite} color="primary">
              接受
          </Button>
            <Button onClick={this.unacceptInvite} color="primary" autoFocus>
              拒绝
          </Button>
          </DialogActions>
        </Dialog>
        {
          Array.from(this.socketStore.requestJoinRoomQueue.values()).map(v => (
            <Dialog
              open={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"申请请求"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  用户:{v.OriginatorNick} 申请加入房间，等待确认
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.acceptJoinRoom.bind(this,v.OriginatorId)} color="primary">
                  同意
                </Button>
                <Button onClick={this.unAcceptJoinRoom.bind(this,v.OriginatorId)} color="primary" autoFocus>
                  拒绝
                </Button>
              </DialogActions>
            </Dialog>
          ))
        }
      </div>
    );
  }
}

export default withStyles(styles)(Room);
