import { observable, action } from 'mobx';

export default class SocketMsg {
  @observable
  public OriginatorId: string;
    @observable
    public OriginatorNick: string;
  @observable
  public inviteeid: string;
  @observable
  public RoomName: string;
  @observable
  public RoomToken: string;
  @observable
  public isgaree :boolean = false;
  
    public constructor(OriginatorId: string, OriginatorNick: string, inviteeid: string, roomname: string,roomToken:string)   
    {
        this.OriginatorId = OriginatorId;
        this.OriginatorNick = OriginatorNick;

        this.inviteeid = inviteeid;
        this.RoomName = roomname;
        this.isgaree = false;
        this.RoomToken = roomToken;
    }
}